<template>
  <div class="place-type">
    <div class="place-type__title">Выберите шаблон</div>
    <div class="row">
      <template v-for="(item, index) in placeList">
        <div class="col-3 mb-sm" :key="index">
          <label class="place-type-radio">
            <input type="radio" :value="item.value" v-model="type" @change="change" class="place-type-radio__field" />
            <div class="place-type-radio__vue">
              <div class="place-type-radio__icon">
                <img class="place-type-radio__img" :src="item.img" alt="icon">
              </div>
              <div class="place-type-radio__text">{{ item.label }}</div>
            </div>
          </label>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "placeType",
  props: {
    placeList: Array,
    currentType: [Number, String],
  },
  data() {
    return {
      type: this.currentType,
    };
  },
  methods: {
    change() {
      this.$emit('change', this.type);
    }
  }
};
</script>

<style lang="scss" scoped>
// fix row
.row {
  margin-left: -8px;
  margin-right: -8px;
  .col-3 {
    padding: 0 8px;
  }
}
// place-type
.place-type {
  margin-bottom: 8px;
  .place-type__title {
    margin-bottom: 24px;
    color: var(--text-prime);
    font-size: 17px;
  }
  .place-type-radio {
    display: block;
    cursor: pointer;
    .place-type-radio__vue {
      padding: 72px 16px 38px;
      text-align: center;
      border-radius: 4px;
      background-color: var(--br-block-prime);
      transition: all .2s ease-in-out;
      .place-type-radio__icon {
        margin-bottom: 6px;
      }
      .place-type-radio__text {
        font-size: 17px;
      }
    }
    .place-type-radio__field {
      display: none;
      &:checked + .place-type-radio__vue {
        background-color: var(--br-block-sub);
      }
    }
  }
}

</style>
