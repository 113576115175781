<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Добавить площадку</h1>
      </div>
    </div>
    <PlaceType
      :currentType="placeType"
      :placeList="placeTypeList"
      @change="changeType"
    />
    <div class="btn-gp">
      <b-button size="lg" variant="danger" @click="save" :disabled="!placeType">Далее</b-button>
      <router-link :to="{name: this.$routeName.PLACE_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
    </div>
  </div>
</template>

<script>

import PlaceType from "@component/Module/PlaceType";

export default {
  components: {
    PlaceType,
  },
  data() {
    return {
      placeType: null,
      placeTypeList: [
        {
          img: require('@img/icon/place/place-club.svg'),
          value: 'club',
          label: 'Площадка клуба',
        },
        {
          img: require('@img/icon/place/place-separate.svg'),
          value: 'single',
          label: 'Отдельная площадка',
        }
      ]
    };
  },
  methods: {
    save() {
      if (this.placeType === 'club') {
        this.$router.push({name: this.$routeName.PLACE_CLUB_ADD });
      } else {
        this.$router.push({name: this.$routeName.PLACE_SINGLE_ADD });
      }
    },
    changeType(type) {
      this.placeType = type;
    }
  }
};
</script>

